<template>
  <div id="safe">
    <tmpl_header />

    <div class="container">
      <sidebar act="safe" />

      <div class="main">
        <section class="card">
          <div class="level">
            <label>安全级别</label>
            <span
              :class="{ low: 1 >= level, middle: 2 == level, high: 3 == level }"
              >{{ 1 >= level ? "低" : level == 2 ? "中" : "高" }}</span
            >
            <i v-if="level < 3">建议启动全部安全设置，以保障帐户及资金安全</i>
          </div>

          <ul class="setting">
            <li class="password">
              <strong>登录密码</strong>
              <p>互联网账号存在被盗风险，建议您定期更改密码以保护账号安全</p>
              <router-link to="/center/safe/password">修改</router-link>
            </li>
            <li class="email">
              <strong>邮箱验证</strong>
              <p>验证后，可用于快速找回密码</p>
              <router-link
                :to="`/center/safe/email?mode=${isEmail ? 'edit' : 'verify'}`"
                >{{ isEmail ? "修改" : "验证" }}</router-link
              >
            </li>
            <li class="mobile">
              <strong>手机验证</strong>
              <p>您的验证手机：{{ mobile }}</p>
              <router-link to="/center/safe/mobile">修改</router-link>
            </li>
          </ul>
        </section>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>
<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import sidebar from "./sidebar.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,

      level: 0,
      isEmail: 0,
      isMobile: 0,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    sidebar,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
    mobile() {
      return (
        store.getters.customerName.substr(0, 3) +
        "****" +
        store.getters.customerName.substr(7, 4)
      );
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/info") },
      });

    api.all([this.loadData()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadData: function () {
      return api.post(apis.center.safe, {}).then((res) => {
        this.isEmail = parseInt(res.isEmail, 10);
        this.isMobile = parseInt(res.isMobile, 10);
        this.level = parseInt(res.level, 10);
      });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./safe.less");
</style>
